import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Conditional from 'Components/common/conditional';
import LateralCarousel, {
	LateralCarouselOnSlideChanged,
	LateralCarouselRef,
} from 'Components/common/lateralCarousel';
import CollectionCard from 'Components/desktop/collectionCard';

import {
	getCollectionCardIdsByCategoryId,
	getCollectionCardIdsByCityCode,
	getCollectionCardIdsByPersonaId,
	getCollectionCardIdsBySubcategoryId,
} from 'Utils/stateUtils';

import { TOP_ATTRACTIONS_COUNT } from 'Constants/constants';

interface ITopAttractionCarouselElement {
	currentCollectionId: string;
	isCategoryPage?: boolean;
	showPrice?: boolean;
	collectionIds: string[];
	showAllCards?: boolean;
	onSlideChanged: LateralCarouselOnSlideChanged;
	attractionsCarouselRef: LateralCarouselRef;
	attractionsCountLimit?: number;
	parentIdentifier?: string;
}

const TopAttractionCarouselElement = ({
	currentCollectionId,
	isCategoryPage,
	showPrice,
	collectionIds,
	showAllCards,
	onSlideChanged,
	attractionsCarouselRef,
	attractionsCountLimit,
	parentIdentifier,
}: ITopAttractionCarouselElement) => {
	const isCardsArrowsRequired = collectionIds.length > 6;
	let collectionCards = collectionIds
		.filter((collectionId: string) => currentCollectionId !== collectionId)
		.map((collectionId, index) => (
			<CollectionCard
				key={collectionId}
				collectionNamePrefix={isCategoryPage && index + 1}
				id={collectionId}
				showPrice={showPrice}
				index={index}
				parentIdentifier={parentIdentifier}
			/>
		));

	if (!showAllCards) {
		collectionCards = collectionCards.slice(
			0,
			attractionsCountLimit ?? TOP_ATTRACTIONS_COUNT,
		);
	}

	const settings = {
		className: 'cities-card-list',
		elementsToSlide: 5,
		elementsToShow: 6,
		hidePrevNextArrows: !!attractionsCarouselRef && !!onSlideChanged,
	};
	const containerClass = classNames({
		'horizontal-scroll-wrapper': true,
		'cities-list-v2-scroll-wrapper': true,
		'no-arrow-button-city-search': !isCardsArrowsRequired,
	});

	return (
		<div className={containerClass}>
			<Conditional if={isCardsArrowsRequired}>
				<LateralCarousel
					buttonsFromTop={'13.8rem'}
					isCollectionCarousel
					onSlideChanged={onSlideChanged}
					carouselRef={attractionsCarouselRef}
					{...settings}
				>
					{collectionCards}
				</LateralCarousel>
			</Conditional>

			<Conditional if={!isCardsArrowsRequired}>
				<LateralCarousel {...settings}>
					{collectionCards}
				</LateralCarousel>
			</Conditional>
		</div>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const { categoryId, subCategoryId, cityCode, personaId } = ownProps;
	let collectionIds;

	if (subCategoryId)
		collectionIds = getCollectionCardIdsBySubcategoryId(
			state,
			subCategoryId,
		);
	else if (categoryId)
		collectionIds = getCollectionCardIdsByCategoryId(state, categoryId);
	else if (personaId)
		collectionIds = getCollectionCardIdsByPersonaId(
			state,
			cityCode,
			personaId,
		);
	else collectionIds = getCollectionCardIdsByCityCode(state, cityCode);

	return { collectionIds };
};

const TopAttractionCarousel = connect(mapStateToProps)(
	TopAttractionCarouselElement,
);

(TopAttractionCarousel as any).propTypes = {
	renderImagesInServer: PropTypes.bool,
	showAllCards: PropTypes.bool,
	noOfCards: PropTypes.number,
	showAlphabetically: PropTypes.bool,
	parentIdentifier: PropTypes.string,
};

(TopAttractionCarousel as any).defaultProps = {
	renderImagesInServer: false,
	showAllCards: false,
	showPrice: true,
};

export default TopAttractionCarousel;
